import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { colors } from "../consts/style"
import Img from "gatsby-image"
import Seo from "../components/Seo"
import ContactForm from "../components/ContactForm/ContactForm"
import BtnPrimary from "../components/buttons/ButtonRounded"
import { FormattedMessage} from 'react-intl';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  SectionTitle,
  Text,
  BgWrap,
  Grid2Col,
  Spacer,
} from "../components/Elements"

export const accesContactQuery = graphql`
  query accesContactQuery($locale: String) {
    plan: file(relativePath: { eq: "plan-acces.png" }) {
      childImageSharp {
      
        fluid(quality: 80, maxWidth: 566) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    page: datoCmsAccesContactPage(locale: {eq: $locale}) {
      titre
      contenu
      titreContact
      contenuContact
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
const AccesContactPage =  ({ data }) => {

  const {
    titre,
    contenu,
    titreContact,
    contenuContact,
    seoMetaTags,
  } = data.page

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
        <PageInner>
          <PageTitle>{titre}</PageTitle>
          <Grid2Col>
            <div>
              <Img fluid={data.plan.childImageSharp.fluid} alt="acces bollène aventure"/>

              <Spacer />
            </div>
            <div>
              <Text dangerouslySetInnerHTML={{ __html: contenu }} />
            
              <BtnPrimary as="a" rel="noopener noreferer nofollow" href="https://www.google.com/maps/dir//BOLLENE+AVENTURE,+1800+chemin+de+guffiage,+84500+Boll%C3%A8ne/@44.294081,4.787256,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12b59e23d3310839:0x216c1f6157d9d2f4!2m2!1d4.787256!2d44.294081" target="_blank">
  <FormattedMessage id="btn__itineraire"/>
              </BtnPrimary>
              <Spacer />
            </div>
          </Grid2Col>
        </PageInner>
        <Spacer id="contact" />
        <BgWrap color="#FBF9F9" >
          <PageInner>
            <Grid2Col>
              <div>
                <SectionTitle dangerouslySetInnerHTML={{ __html: titreContact }}/>
                <Text
                  color={colors.dark}
                  dangerouslySetInnerHTML={{ __html: contenuContact }}
                />
                <ContactForm />
              </div>
            </Grid2Col>
          </PageInner>
        </BgWrap>
      </PageWrapper>
    </Fragment>
  )
}

export default  AccesContactPage